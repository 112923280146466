<template>
  <v-container>
    <v-alert
      text
      prominent
      type="error"
      icon="mdi-cloud-alert"
      v-if="alertError != ''"
    >
      {{ alertError }}
    </v-alert>
    <v-alert
      text
      prominent
      type="success"
      icon="mdi-cloud-alert"
      v-if="alertSuccess != ''"
    >
      {{ alertSuccess }}
    </v-alert>
    <v-card outlined class="pa-5 mb-2">
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="5 my-lg-auto text-center">
              <p class="text-h6 mb-1">
                <v-icon>mdi-account-supervisor-circle</v-icon>Country
              </p>
            </v-col>
            <v-col cols="7">
              <v-select
                v-model="country"
                outlined
                :items="countries"
                item-text="name"
                menu-props="auto"
                label="Select Country"
                hide-details
                return-object
                single-line
                @change="SelectState"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="5 my-lg-auto text-center">
              <p class="text-h6 mb-1">
                <v-icon>mdi-account-supervisor-circle</v-icon>State
              </p>
            </v-col>
            <v-col cols="7">
              <v-select
                v-model="state"
                outlined
                :items="states"
                item-text="name"
                menu-props="auto"
                label="Select State"
                hide-details
                return-object
                single-line
                @change="SelectSchoolDistrict"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="5 my-lg-auto text-center">
              <p class="text-h6 mb-1">
                <v-icon>mdi-account-supervisor-circle</v-icon>School Districts
              </p>
            </v-col>
            <v-col cols="7">
              <v-select
                v-model="district"
                outlined
                :items="schoolDistricts"
                item-text="name"
                menu-props="auto"
                label="Select Districts"
                hide-details
                return-object
                single-line
                @change="SelectSchool"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>List Schools</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn outlined @click="CrearEscuelasHard">
          <v-icon>mdi-plus</v-icon>Hard Save
        </v-btn> -->
        <v-btn outlined @click="AddSchool">
          <v-icon>mdi-plus</v-icon>Add School
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="tableHeader"
        :items="schools"
        sort-by="School"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon> -->
          <v-btn
            class="ma-2"
            icon
            color="indigo lighten-2"
            @click="editSchool(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="ma-2"
            icon
            color="green lighten-2"
            @click="ListTeachersSchool(item)"
            v-if="item.MultiplayerLicense"
          >
            <v-icon>mdi-account-multiple</v-icon>
          </v-btn>
          <v-btn
            class="ma-2"
            icon
            color="green lighten-2"
            @click="ListTeachersSchool(item, 'classroom')"
            v-if="item.StudentLicencesAssigned>0 || item.TeacherLicencesAssigned>0"
          >
            <v-icon>mdi-google-circles-extended</v-icon>
          </v-btn>
          <v-btn
            class="ma-2"
            icon
            color="orange lighten-1"
            @click="ListTeacherDownloads(item)"
          >
            <v-icon>mdi-file-chart</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-5">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Schools without licences</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        :headers="tableHeader"
        :items="schoolsFree"
        sort-by="School"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon> -->
          <v-btn
            class="ma-2"
            icon
            color="indigo lighten-2"
            @click="editSchool(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="ma-2"
            icon
            color="green lighten-2"
            @click="ListTeachersSchool(item, 'classroom')"
            v-if="!item.MultiplayerLicense"
          >
            <v-icon>mdi-google-circles-extended</v-icon>
          </v-btn>
          <v-btn
            class="ma-2"
            icon
            color="orange lighten-1"
            @click="ListTeacherDownloads(item)"
          >
            <v-icon>mdi-file-chart</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogSchool" max-width="1000px">
      <v-card>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="save"
          lazy-validation
        >
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="school.name"
                    :rules="nameRules"
                    label="Name"
                    prepend-icon="mdi-account"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="school.teachers"
                    type="number"
                    :label="labelTeacher"
                    prepend-icon="mdi-account"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="school.students"
                    type="number"
                    :label="labelStudent"
                    prepend-icon="mdi-account"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    v-model="PickerExpiration"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="school.PickerExpiration"
                        label="Date Expiration"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="school.PickerExpiration"
                      @input="PickerExpiration = false"
                      :min="nowDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="school.MultiplayerLicense"
                    type="number"
                    label="Number of Multiplayer Licences"
                    prepend-icon="mdi-controller-classic"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="school.ExactLicences"
                    :label="`Exact Licences: ${school.ExactLicences.toString()}`"
                  ></v-switch>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Save </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogListTeachers" max-width="700px">
      <v-card style="height: 100%">
        <!--<v-toolbar flat color="cyan lighten-4" dense>
          <p class="mb-0 text-h6 mx-auto">Select your Teachers</p>
        </v-toolbar>-->

        <v-toolbar flat color="cyan lighten-4" dense class="blue-grey--text">
          <v-btn
            icon
            dark
            @click="dialogListTeachers = false"
          >
            <v-icon color="blue-grey">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Select your Teachers for multiplayer</v-toolbar-title>          
        </v-toolbar>

        <!--<v-switch
          v-model="switchMultiplayerAll"
          @change="OnOffALLMultiplayer"
          label="Activar/Desactivar todo MULTIPLAYER"
        ></v-switch>-->
        <v-row justify="center" class="ma-5" style="height: 100%">
          <v-col cols="8">
          <v-data-table
            dense
            hide-default-footer
            disable-pagination
            :headers="headerTableTeachers"
            :items="ListTeachers"
            class="elevation-1"
            id="tablaExportar"
          >          
          <!--<template v-slot:[`item.multiplayer`]="{ item }">          
            <v-switch
              v-model="item.Multiplayer"
              @change="ActiveMultiplayer(item)"
            ></v-switch>
          </template>-->
          <template v-slot:header.multiplayer="{ header }">
            Multiplayer <input type="checkbox" v-model="allCheckM" @change="allCheckBoxMultiplayer">
          </template> 
          <template v-slot:[`item.multiplayer`]="{ item }">
            <div class="text-center">
            <input type="checkbox" id="checkboxM" v-model="item.Multiplayer" @change="ActiveMultiplayer(item)"><br/>
            <label for="checkboxM">{{ item.Multiplayer?'Active':'Inactive' }}</label>
            </div>
          </template>
          <!--<template v-slot:[`item.students`]="{ item }">            
            <v-switch
              v-model="item.Licences"
              @change="OnOffLimitedStudents(item)"
            ></v-switch>
          </template>-->          
          </v-data-table>
          </v-col>
          <v-col cols="4">
            <v-card class="p-0">
              <v-card-title>Instrucciones</v-card-title>
              <v-card-text>
                Si deseas que al entrar en la app los estudiantes tenga la opción multiplayer, como se muestra en la imagen:
                <v-img src="../../../assets/admin/multiplayer_intro.jpg"></v-img><br>
                Busca el profesor y solo debes habilitar esta opción:
                <v-img src="../../../assets/admin/enabled.gif"></v-img><br>
                Y listo el profesor tendría habilitado esta opción como se muestra en la imagen:
                <v-img src="../../../assets/admin/multiplayer_gamers.jpg"></v-img>
              </v-card-text>  
            </v-card>
          </v-col>
        </v-row>        
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogListTeachersClassrooms" max-width="700px">
      <v-card style="height: 100%">
        <!--<v-toolbar flat color="cyan lighten-4" dense>
          <p class="mb-0 text-h6 mx-auto">Select your Teachers</p>
        </v-toolbar>-->

        <v-toolbar flat color="cyan lighten-4" dense class="blue-grey--text">
          <v-btn
            icon
            dark
            @click="dialogListTeachersClassrooms = false"
          >
            <v-icon color="blue-grey">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>List of teachers for Classroom</v-toolbar-title>          
        </v-toolbar>

        <v-row justify="center" class="ma-5" style="height: 100%">
          <v-col cols="8">
            <v-data-table
              dense
              hide-default-footer
              disable-pagination
              :headers="headerTableTeachersClassrooms"
              :items="ListTeachers"
              class="elevation-1"
              id="tablaExportar"
            >
              <template v-slot:header.HasClassrooms="{ header }">
                Classroom <input type="checkbox" v-model="allCheck" @change="allCheckBoxClassrooms">
              </template>          
              <template v-slot:[`item.HasClassrooms`]="{ item }">
                <div class="text-center">
                <input type="checkbox" id="checkbox" v-model="item.HasClassrooms" @change="ActiveClassrooms(item)"><br/>
                <label for="checkbox">{{ item.HasClassrooms?'Active':'Inactive' }}</label>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="4">
            <v-card class="p-0">
              <v-card-title>Instrucciones</v-card-title>
              <v-card-text>
                Si deseas que al entrar un usuario teacher no aparezca deshabilitado la opción Classroom, como se muestra en la imagen:
                <v-img src="../../../assets/admin/classroom-disabled.gif"></v-img><br>
                Busca el profesor y solo debes habilitar esta opción:
                <v-img src="../../../assets/admin/enabled.gif"></v-img><br>
                Y listo el profesor tendría habilitado esta opción como se muestra en la imagen:
                <v-img src="../../../assets/admin/classroom-enabled.jpg"></v-img>
              </v-card-text>  
            </v-card>
          </v-col>
        </v-row>        
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogListTeachersDownloads" max-width="600px">
      <v-card style="height: 100%">
        <v-toolbar flat color="orange lighten-1" dense>
          <p class="mb-0 text-h6 mx-auto">Resources downloaded in this school</p>
        </v-toolbar>
        <v-row justify="center" class="" style="height: 100%" theme="dark">
          <v-col cols="12">
          <v-data-table
            dense
            hide-default-footer
            disable-pagination
            :headers="headerTableTeachersDownloads"
            :items="ListTeachersDownloads"
            hide-default-header
            class="elevation-1 mx-1 mt-3"
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th v-for="h in headers" :class="h.class">
                    <span>{{h.text}}</span>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="error" icon="warning">
                Sorry, nothing to display here :(
              </v-alert>
            </template>          
            <template v-slot:item.Downloads="{item}" class="px-0">
              <div v-if="item.TotalDownloads==0" style="color: red">Has no downloads</div>
              <v-btn v-else-if="item.TotalDownloads>0" color="" class="orange lighten-4" text @click="showDownload(item)"> {{ item.DisplayDiv ? 'Hide Downloads' : 'Show Downloads' }} <v-icon left>{{ item.DisplayDiv ? 'mdi-menu-right' : 'mdi-menu-down' }} </v-icon> </v-btn>
              <div class="d-table downloadContent" v-if="item.DisplayDiv">
                <div class="d-table-row amber lighten-5">
                  <div class="d-table-cell downloadContent text-center font-weight-bold">Title</div>
                  <div class="d-table-cell downloadContent text-center font-weight-bold">Num of Downloads</div>
                  <div class="d-table-cell downloadContent text-center font-weight-bold">Category</div>
                </div>
                <div class="d-table-row" v-for="(download, index) in item.Downloads" :key="index">
                  <div class="d-table-cell downloadContent">{{ download.Title }}</div>
                  <div class="d-table-cell downloadContent text-right">{{ download.Downloads }}</div>
                  <div class="d-table-cell downloadContent">{{ download.Category }}</div>
                </div>
                <div class="d-table-row">
                  <div class="d-table-cell downloadContent text-right font-weight-bold">Total Downloads</div>
                  <div class="d-table-cell downloadContent text-right">{{item.TotalDownloads}}</div>
                </div>
              </div>
            </template>                 
          </v-data-table>
        </v-col>
        </v-row>        
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300" style="z-index: 9999;">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6" text color="red" @click="sheet = !sheet">
          close
        </v-btn>
        <div class="py-3">This user is disabled</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
import { db, functions } from "../../../plugins/firebase";
import firebase from "firebase";
export default {
  data() {
    return {
      tableHeader: [
        { text: "School", value: "name" },
        { text: "TE Assigned", value: "TeacherLicencesAssigned" },
        { text: "TE Used", value: "AvailableTeacherLicences" },
        { text: "SE Assigned", value: "StudentLicencesAssigned" },
        { text: "SE Used", value: "AvailableStudentLicences" },
        { text: "License Pre-Registration", value: "TeacherRegister" },
        { text: "Multiplayer License", value: "MultiplayerLicense" },
        { text: "DateExpiration", value: "expirationDate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      users: [],
      school: {
        name: "",
        country: "",
        state: "",
        schoolDistrict: "",
        teachers: "",
        students: "",
        PickerExpiration: "",
        MultiplayerLicense: 0,
        ExactLicences: false
      },
      nameRules: [
        (v) => (v && v.length >= 2) || "Minimum length is 2 characters",
        (v) => (v && v.length <= 60) || "Name must be less than 60 characters",
      ],
      clearSchool: {
        name: "",
        country: "",
        state: "",
        schoolDistrict: "",
        teachers: "",
        students: "",
        PickerExpiration: "",
        MultiplayerLicense: 0,
        ExactLicences: false
      },
      country: "",
      countries: [],
      state: "",
      states: [],
      district: "",
      schoolDistricts: [],
      schools: [],
      schoolsFree: [],
      editedUID: -1,
      dialogSchool: false,
      dialogDelete: false,
      dialogLoad: false,
      valid: true,
      PickerExpiration: false,
      alertError: "",
      alertSuccess: "",
      showPassword: false,
      table: "SchoolAdmin",
      sheet: false,
      dialogListTeachers: false,
      dialogListTeachersClassrooms: false,
      dialogListTeachersDownloads: false,
      headerTableTeachers: [
        { text: 'Full Name', value: 'name' },
        { text: 'Multiplayer', value: 'multiplayer', sortable: false },
        //{ text: 'Limit Students', value: 'students', sortable: false }
      ],
      headerTableTeachersClassrooms: [
        { text: 'Full Name', value: 'name' },       
        { text: 'Classroom', value: 'HasClassrooms', sortable: false },
      ],
      headerTableTeachersDownloads: [
        { text: 'Teacher', value: 'Teacher', class: 'my-header-style' },
        { text: 'Downloads', value: 'Downloads', class: 'my-header-style' },
      ],
      ListTeachersDownloads:[],
      ListTeachers:[],
      NamesGroups: ["1st Group","2nd Group","3rd Group","4th Group","5th Group","6th Group","7th Group","8th Group"],
      SchoolSelected: {},
      nowDate: new Date().toISOString().slice(0,10),
      switchMultiplayerAll: false,
      allCheck: false,
      allCheckM: false,
      hardsave: {
        schools:[
        // 'asdasd',
        ],
        PickerExpiration: "2024-07-31",
        ExactLicences: true,
        MultiplayerLicense: 0,
        country: "",
        // name:''
        schoolDistrict: "",
        state: "",
        students: "0",
        teachers: "0"
      }

    };
  },
  created() {
    this.ListCountries();
  },
  methods: {
    async ListCountries() {
      await db
        .collection("SchoolsData")
        .doc("RegionSchoolD")
        .get()
        .then((doc) => {
          let Countries = doc.data().Countries;
          let ids = Object.keys(Countries);
          let newCountries = [];
          ids.forEach((element) => {
            newCountries.push({ id: element, name: Countries[element] });
          });
          this.countries = newCountries;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    AddSchool() {
      if (this.country == "" || this.state == "" || this.district == "") {
        this.alertError = "necesita seleccionar school district";
        let principal = this;
        setTimeout(function () {
          principal.alertError = "";
        }, 3000);
        return;
      }

      this.dialogSchool = true;
    },
    editSchool(data) {
      if (this.country == "" || this.state == "" || this.district == "") {
        this.alertError = "necesita seleccionar school district";
        let principal = this;
        setTimeout(function () {
          principal.alertError = "";
        }, 3000);
        return;
      }
      console.log(data);
      this.editedUID = data.id;
      // this.school = Object.assign({}, data)
      this.school.name = data.name;
      this.school.teachers = 0;
      this.school.students = 0;
      this.school.PickerExpiration = "";
      this.school.Reference = data.Reference
      this.school.SchoolAdmins = data.SchoolAdmin
      this.school.SchoolTeachers = data.SchoolTeachers
      if (data.MultiplayerLicense == undefined) {
        this.school.MultiplayerLicense = 0;
      } else {
        this.school.MultiplayerLicense = data.MultiplayerLicense;
      }
      this.school.ExactLicences = data.ExactLicences;
      this.school.teacherRegister = data.TeacherRegister;
      this.dialogSchool = true;
    },
    async ListTeachersSchool(school, from='multiplayer') {
      this.ListTeachers = [];
      this.SchoolSelected = school
      if (from=='multiplayer')
        this.dialogListTeachers = true;
      else
        this.dialogListTeachersClassrooms = true;
      // console.log(school);
      this.dialogLoad = true;
      let docRef = await db
        .collection("SchoolsData")
        .doc("RegionSchoolD")
        .collection("Countries")
        .doc(this.country.id)
        .collection("States")
        .doc(this.state.id)
        .collection("SchoolDistricts")
        .doc(this.district.id)
        .collection("Schools")
        .doc(school.id)
        .get();
      // console.log(docRef.data());
      let ListaTeachers = docRef.data().SchoolTeachers;
      let ids = Object.keys(ListaTeachers);
      let ListaMaestrosMultiplayer = []
      let allCheckBoxs=true;
      let allCheckBoxsM=true;
      for (const idTeacher of ids) {
        // console.log(ListaTeachers[idTeacher]);
        let TeacherData = await db.collection("SchoolTeacher").doc(idTeacher).get();
        let MultiplayerActive = (TeacherData.data().Multiplayer == undefined || !TeacherData.data().Multiplayer)? false: true;
        if (!MultiplayerActive)
          allCheckBoxsM=MultiplayerActive;

        let ClassroomsActive = false;
        if (TeacherData.data().TypeLogin=='clever')  
          ClassroomsActive = (Object.prototype.hasOwnProperty.call(TeacherData.data(), 'HasClassrooms')?TeacherData.data().HasClassrooms: false);
        else
          ClassroomsActive = (Object.prototype.hasOwnProperty.call(TeacherData.data(), 'HasClassrooms')?TeacherData.data().HasClassrooms: true);

        if (!ClassroomsActive)
          allCheckBoxs=ClassroomsActive;
        ListaMaestrosMultiplayer.push({ name:TeacherData.data().Name + " "+ TeacherData.data().LastName, id: idTeacher, Multiplayer: MultiplayerActive, HasClassrooms: ClassroomsActive, Classroom: TeacherData.data().Classrooms, Licences: (TeacherData.data().ExactLicences == undefined) ? false : TeacherData.data().ExactLicences, TypeLogin: TeacherData.data().TypeLogin })
        // console.log(TeacherData.data().ExactLicences);
      }
      if (from=='multiplayer')
        this.allCheckM=allCheckBoxsM;
      else
        this.allCheck=allCheckBoxs;

      this.ListTeachers = ListaMaestrosMultiplayer.sort((a, b) => {
        if (a.Name < b.Name) {
            return -1;
        }
        if (a.Name > b.Name) {
            return 1;
        }
        return 0;
      });
      // this.ListTeachers = ListaMaestrosMultiplayer;
      this.dialogLoad = false;
    },
    async ListTeacherDownloads(school) {  console.log(school);    
      this.ListTeachersDownloads=[];   
      if (Object.prototype.hasOwnProperty.call(school.SchoolTeachers, 'Downloads')){
        this.alertError = 'This user has no downloads.';
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return
      }
      
      this.SchoolSelected = school
      this.dialogListTeachersDownloads = true;
      let STeachers=school.SchoolTeachers;
      for (let idTeacher in STeachers){              
        db.collection("SchoolTeacher").doc(idTeacher).get().then((data) => {
          let teacher=data.data();
          let dataDownloads=[];
          let sumDownloads=0;
          for (let download in teacher.Downloads) {
            if (download.slice(0,3)=="RCS"){
              sumDownloads+=teacher.Downloads[download].Downloads;
              dataDownloads.push(teacher.Downloads[download]);
            }
          }console.log(dataDownloads);          
          this.ListTeachersDownloads.push({Teacher: teacher.Name+' '+teacher.LastName, Downloads: dataDownloads, TotalDownloads: sumDownloads, DisplayDiv: false});
        });
      }
    },
    showDownload(item){
      item.DisplayDiv = !item.DisplayDiv;
    },
    closeList(){
      this.SchoolSelected = {};
    },
    deleteUser(data) {
      this.editedUID = data.id;
      this.user = Object.assign({}, data);
      this.dialogDelete = true;
    },
    deleteUserConfirm() {
      this.deleteData(this.editedUID).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "deleted successfully";
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeDelete();
    },
    close() {
      this.dialogSchool = false;
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.school = Object.assign({}, this.clearSchool);
        this.editedUID = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser);
        this.editedUID = -1;
      });
    },
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.editedUID !== -1) {
        this.dialogLoad = true;
        this.updateData(this.school).then((response) => {
          if (response.result) {
            this.alertSuccess = "se actualizo correctamente ";
            let principal = this;
            setTimeout(function () {
              principal.alertSuccess = "";
            }, 3000);
          } else {
            let principal = this;
            if (
              Object.prototype.hasOwnProperty.call(response.message, "code")
            ) {
              switch (response.message.code) {
                case "not-found":
                  this.alertError = "no se encontro el documento";
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () {
              principal.alertError = "";
            }, 3000);
          }
          this.dialogLoad = false;
        });
      } else {
        this.dialogLoad = true;
        this.createData(this.school).then((response) => {
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () {
              principal.alertError = "";
            }, 3000);
          } else {
            this.alertSuccess = "se creo correctamente ";
            let principal = this;
            setTimeout(function () {
              principal.alertSuccess = "";
            }, 3000);
          }
          this.dialogLoad = false;
        });
      }
      this.close();
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.validate();
    },
    async CrearEscuelasHard(){

      for (const schoolName of this.hardsave.schools) {
        console.log(schoolName);
        await this.createData({PickerExpiration: "2024-07-31", ExactLicences: true, MultiplayerLicense: 0, country: "", name:schoolName, schoolDistrict: "", state: "", students: "0", teachers: "0"})
      }
    },
    // llamadas a firebase
    async createData(school) {
      let fechaFinal = new Date(school.PickerExpiration);
      fechaFinal.setDate(fechaFinal.getDate() + 1);

      let answer = { result: "", message: "" };
      try {
        let uidSuperAdmin = this.$store.state.user;
        if (uidSuperAdmin == null) {
          throw new Error("no existe el usuario");
        }

        let docRef = await db
          .collection("SchoolsData")
          .doc("RegionSchoolD")
          .collection("Countries")
          .doc(this.country.id)
          .collection("States")
          .doc(this.state.id)
          .collection("SchoolDistricts")
          .doc(this.district.id)
          .collection("Schools")
          .add({
            SchoolAdmins: {},
            SchoolTeachers: {},
            PendingUsers: {},
            StudentLicencesAssigned: Number(school.students),
            TeacherLicencesAssigned: Number(school.teachers),
            AvailableStudentLicences: Number(school.students),
            AvailableTeacherLicences: Number(school.teachers),
            MultiplayerLicenses: Number(school.MultiplayerLicense),
            SchoolName: school.name,
            ExactLicences: school.ExactLicences,
            DateExpired:
              firebase.firestore.Timestamp.fromDate(fechaFinal).toDate(),
          });
        console.log("Document written with ID: ", docRef.id);
        // console.log(docRef.data());
        console.log(school.name);
        await db
          .collection("SchoolsData")
          .doc("RegionSchoolD")
          .collection("Countries")
          .doc(this.country.id)
          .collection("States")
          .doc(this.state.id)
          .collection("SchoolDistricts")
          .doc(this.district.id)
          .collection("Schools")
          .doc(docRef.id)
          .collection("StudentsData")
          .doc("P1")
          .set({
            Students: {},
          });
        await db
          .collection("SchoolsData")
          .doc("RegionSchoolD")
          .collection("Countries")
          .doc(this.country.id)
          .collection("States")
          .doc(this.state.id)
          .collection("SchoolDistricts")
          .doc(this.district.id)
          .collection("Schools")
          .doc(docRef.id)
          .collection("Multiplayer")
          .doc("Available")
          .set({
            Licences: Number(school.MultiplayerLicense),
            Users: {},
          });
        await db
          .collection("SchoolsData")
          .doc("RegionSchoolD")
          .collection("Countries")
          .doc(this.country.id)
          .collection("States")
          .doc(this.state.id)
          .collection("SchoolDistricts")
          .doc(this.district.id)
          .update({
            [`Schools.${[docRef.id]}`]: school.name,
          });
        // await db
        //   .collection("SchoolsData")
        //   .doc("RegionSchoolD")
        //   .collection("Countries")
        //   .doc(this.country.id)
        //   .collection("States")
        //   .doc(this.state.id)
        //   .collection("SchoolDistricts")
        //   .doc(this.district.id)
        //   .update({
        //     [`Schools2`]: firebase.firestore.FieldValue.arrayUnion({id:docRef.id, name:school.name}),
        //   });
        this.SelectSchool();
        answer.result = true;
      } catch (error) {
        console.log(error);
        answer.result = false;
        answer.message = error;
        // console.log(error);
      }
      return answer;
    },
    async updateData() {
      let answer = { result: "", message: "" };
      try {
        const batch = db.batch();
        let step1 = db
          .collection("SchoolsData")
          .doc("RegionSchoolD")
          .collection("Countries")
          .doc(this.country.id)
          .collection("States")
          .doc(this.state.id)
          .collection("SchoolDistricts")
          .doc(this.district.id)
          .collection("Schools")
          .doc(this.editedUID);
        batch.update(step1, {
          StudentLicencesAssigned: firebase.firestore.FieldValue.increment(
            this.school.students
          ),
          TeacherLicencesAssigned: firebase.firestore.FieldValue.increment(
            this.school.teachers
          ),
          AvailableStudentLicences: firebase.firestore.FieldValue.increment(
            this.school.students
          ),
          AvailableTeacherLicences: firebase.firestore.FieldValue.increment(
            this.school.teachers
          ),
          MultiplayerLicenses: Number(this.school.MultiplayerLicense),
          SchoolName: this.school.name,
          ExactLicences: this.school.ExactLicences
        });
        let step2 = db
          .collection("SchoolsData")
          .doc("RegionSchoolD")
          .collection("Countries")
          .doc(this.country.id)
          .collection("States")
          .doc(this.state.id)
          .collection("SchoolDistricts")
          .doc(this.district.id);
        batch.update(step2, {
          [`Schools.${[[this.editedUID]]}`]: this.school.name,
        });
        let step3 = db
          .collection("SchoolsData")
          .doc("RegionSchoolD")
          .collection("Countries")
          .doc(this.country.id)
          .collection("States")
          .doc(this.state.id)
          .collection("SchoolDistricts")
          .doc(this.district.id)
          .collection("Schools")
          .doc(this.editedUID)
          .collection("Multiplayer")
          .doc("Available");
        batch.set(
          step3,
          { Licences: Number(this.school.MultiplayerLicense) },
          { merge: true }
        );
        let ShoolTeachers =this.school.SchoolTeachers;
        for (const idTeacher in ShoolTeachers) {
            console.log(idTeacher);
            batch.update(db.collection("SchoolTeacher").doc(idTeacher),{ ExactLicences: this.school.ExactLicences })
          }
        
        if (this.school.PickerExpiration!= "") {
          let fecha = new Date(this.school.PickerExpiration);
          let SchoolAdmins = this.school.SchoolAdmins;
          // let ShoolTeachers =this.school.SchoolTeachers;
          let Studentsdata = await db.doc(this.school.Reference+ "/"+this.editedUID+"/StudentsData/P1").get();
          let SchoolStudents = Studentsdata.data().Students;
          // let StudentsData = Object.keys(SchoolStudents);
          fecha.setDate(fecha.getDate() + 1);
          for (const idAdmin in SchoolAdmins) {
            console.log(idAdmin);
            batch.update(db.collection("SchoolAdmin").doc(idAdmin),{ PickerExpiration:firebase.firestore.Timestamp.fromDate(fecha).toDate() })
          }
          for (const idTeacher in ShoolTeachers) {
            console.log(idTeacher);
            batch.update(db.collection("SchoolTeacher").doc(idTeacher),{ LicenseExpirationDate:firebase.firestore.Timestamp.fromDate(fecha).toDate() })
          }
          for (const idStudent in SchoolStudents) {
            console.log(idStudent);
            batch.update(db.collection("Students").doc(idStudent),{ LicenseExpirationDate:firebase.firestore.Timestamp.fromDate(fecha).toDate() })
          }
          batch.update(step1,{ DateExpired:firebase.firestore.Timestamp.fromDate(fecha).toDate() })
        }
        console.log(ShoolTeachers);
        if (Object.prototype.hasOwnProperty.call(this.school, "teacherRegister")) {
          let registrosTeacher = this.school.teacherRegister
          if (registrosTeacher > 0) {
            for (const idTeacher in ShoolTeachers) {
              console.log(idTeacher);
              if (Object.prototype.hasOwnProperty.call(ShoolTeachers[idTeacher], "IsRegistration")) {
                console.log(ShoolTeachers[idTeacher]);
               
                const UpdateRegister = functions.httpsCallable('ActivarDesactivarRegistroTeacher')
                await UpdateRegister({ idTeacher: idTeacher, status: false });
              }
            }
          }
        }
        await batch.commit();
        answer.result = true;
        this.SelectSchool();
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log("entro al log general");
        console.log(error);
      }
      return answer;
    },
    async deleteData(id) {
      let answer = { resutl: "", message: "" };
      let uidLogin = this.$store.state.user.uid;
      if (uidLogin === id) {
        answer.mensaje = "this user is actually login";
        answer.resultado = false;
        return answer;
      }
      try {
        const deleteAdmin = functions.httpsCallable("deleteSchoolAdmin");
        await deleteAdmin(id).then(() => {
          answer.result = true;
        });
      } catch (error) {
        answer.message = error;
        answer.resutl = false;
      }
      return answer;
    },
    async SelectState() {
      let StateReference = db
        .collection("SchoolsData")
        .doc("RegionSchoolD")
        .collection("Countries")
        .doc(this.country.id);
      await StateReference.get()
        .then((doc) => {
          let ListStates = doc.data().States;
          let ids = Object.keys(ListStates);          
          let newStates = [];
          ids.forEach((element) => {
            newStates.push({ id: element, name: ListStates[element] });
          });          
          newStates.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))         
          this.states = newStates;
          // aqui reiniciamos los demas selects
          this.schoolDistricts = [];
          this.schools = [];
          this.district = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async SelectSchoolDistrict() {
      let SchoolDistrictReference = db
        .collection("SchoolsData")
        .doc("RegionSchoolD")
        .collection("Countries")
        .doc(this.country.id)
        .collection("States")
        .doc(this.state.id);
      await SchoolDistrictReference.get()
        .then((doc) => {
          let ListSchoolDistrict = doc.data().SchoolDistricts;
          let ids = Object.keys(ListSchoolDistrict);
          let newSchoolDistricts = [];
          ids.forEach((element) => {
            newSchoolDistricts.push({
              id: element,
              name: ListSchoolDistrict[element],
            });
          });
          newSchoolDistricts.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) 
          this.schoolDistricts = newSchoolDistricts;
          // aqui reiniciamos los demas selects
          this.schools = [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async SelectSchool() {
      let SchoolReference = db
        .collection("SchoolsData")
        .doc("RegionSchoolD")
        .collection("Countries")
        .doc(this.country.id)
        .collection("States")
        .doc(this.state.id)
        .collection("SchoolDistricts")
        .doc(this.district.id)
        .collection("Schools");
      await SchoolReference.get()
        .then((querySnapshot) => {
          let listSchools = [];
          let listSchoolsFree =[];
          querySnapshot.forEach( async (doc) => {            
            if (doc.data().TeacherLicencesAssigned == 0 && doc.data().StudentLicencesAssigned == 0 && doc.data().ExactLicences && doc.data().ExactLicences== true ) {
              let LicenciasRegistradas = 0;
              if (Object.prototype.hasOwnProperty.call(doc.data(), "LicencesRegistration")) {
                LicenciasRegistradas = doc.data().LicencesRegistration;
              }
              listSchoolsFree.push({
                id: doc.id,
                name: doc.data().SchoolName,
                AvailableTeacherLicences: doc.data().AvailableTeacherLicences,
                AvailableStudentLicences: doc.data().AvailableStudentLicences,
                StudentLicencesAssigned: doc.data().StudentLicencesAssigned,
                TeacherLicencesAssigned: doc.data().TeacherLicencesAssigned,
                expirationDate: doc.data().DateExpired.toDate().toDateString(),
                MultiplayerLicense: doc.data().MultiplayerLicenses,
                SchoolAdmin: doc.data().SchoolAdmins,
                SchoolTeachers: doc.data().SchoolTeachers,              
                Reference: SchoolReference.path,
                ExactLicences: doc.data().ExactLicences,
                TeacherRegister: LicenciasRegistradas
              })
            }else{
              let LicenciasRegistradas = 0;
              if (Object.prototype.hasOwnProperty.call(doc.data(), "LicencesRegistration")) {
                LicenciasRegistradas = doc.data().LicencesRegistration;
              }
              let dataST = await db.doc(SchoolReference.path+'/'+doc.id+'/StudentsData/P1').get()
              let students = Object.keys(dataST.data().Students)
              let teachers = Object.keys(doc.data().SchoolTeachers)
              listSchools.push({
                id: doc.id,
                name: doc.data().SchoolName,
                AvailableTeacherLicences: teachers.length,
                AvailableStudentLicences: students.length,
                StudentLicencesAssigned: doc.data().StudentLicencesAssigned,
                TeacherLicencesAssigned: doc.data().TeacherLicencesAssigned,
                expirationDate: doc.data().DateExpired.toDate().toDateString(),
                MultiplayerLicense: doc.data().MultiplayerLicenses,
                SchoolAdmin: doc.data().SchoolAdmins,
                SchoolTeachers: doc.data().SchoolTeachers,              
                Reference: SchoolReference.path,
                ExactLicences: doc.data().ExactLicences,
                TeacherRegister: LicenciasRegistradas
              });
            }
          });
          listSchoolsFree.sort((a, b) => a.name.localeCompare(b.name));
          listSchools.sort((a, b) => a.name.localeCompare(b.name));   
          this.schoolsFree = listSchoolsFree;
          this.schools = listSchools;
          console.log(this.schools);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GenerateMultiplayerTeacher(){

    },
    async ActiveMultiplayer(teacher){
      console.log(teacher);
      this.dialogLoad = true;
      await db.collection('SchoolTeacher').doc(teacher.id).update({ Multiplayer: teacher.Multiplayer});
      if (teacher.Multiplayer) {
        let idClassroom = Object.keys(teacher.Classroom);
        for (const idClass of idClassroom) {
          let NStudents =teacher.Classroom[idClass].NumberStudents;
          let NGroup =teacher.Classroom[idClass].NumberGroup;
          let NgruposIdeales = Math.floor(NStudents/5)+1;
          if (NgruposIdeales> NGroup) {
            for (let index = NGroup; index < NgruposIdeales; index++) {
              
              let slug = this.NamesGroups[index].replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ').toLowerCase();
              slug = slug.replace(/^\s+|\s+$/gm, '');
              slug = slug.replace(/\s+/g, '-');

              await db.collection('SchoolTeacher').doc(teacher.id).collection('Classrooms').doc(idClass).update({ [`Groups.${[slug]}`]: { Name: this.NamesGroups[index], StudentsList: [] } });
              await db.collection("SchoolTeacher").doc(teacher.id).update({ [`Classrooms.${idClass}.NumberGroup`]: firebase.firestore.FieldValue.increment(1) })
            }  
          }
        } 
      }
      this.ListTeachersSchool(this.SchoolSelected)
      this.dialogLoad= false;
    },
    async ActiveClassrooms(teacher){
      console.log(teacher);
      this.dialogLoad = true;
      await db.collection('SchoolTeacher').doc(teacher.id).update({ HasClassrooms: teacher.HasClassrooms});
      if (teacher.TypeLogin=='clever'){console.log("ActivarDesactivarRegistroTeacher");
        const UpdateRegister = functions.httpsCallable('ActivarDesactivarRegistroTeacher')
        await UpdateRegister({ idTeacher: teacher.id, status: !teacher.HasClassrooms });
      }
      /*if (teacher.Multiplayer) {
        let idClassroom = Object.keys(teacher.Classroom);
        for (const idClass of idClassroom) {
          let NStudents =teacher.Classroom[idClass].NumberStudents;
          let NGroup =teacher.Classroom[idClass].NumberGroup;
          let NgruposIdeales = Math.floor(NStudents/5)+1;
          if (NgruposIdeales> NGroup) {
            for (let index = NGroup; index < NgruposIdeales; index++) {
              
              let slug = this.NamesGroups[index].replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ').toLowerCase();
              slug = slug.replace(/^\s+|\s+$/gm, '');
              slug = slug.replace(/\s+/g, '-');

              await db.collection('SchoolTeacher').doc(teacher.id).collection('Classrooms').doc(idClass).update({ [`Groups.${[slug]}`]: { Name: this.NamesGroups[index], StudentsList: [] } });
              await db.collection("SchoolTeacher").doc(teacher.id).update({ [`Classrooms.${idClass}.NumberGroup`]: firebase.firestore.FieldValue.increment(1) })
            }  
          }
        } 
      }*/
      this.ListTeachersSchool(this.SchoolSelected, 'classroom')
      this.dialogLoad= false;
    },
    async OnOffLimitedStudents(teacher){
      console.log(teacher);
      this.dialogLoad = true;
      await db.collection('SchoolTeacher').doc(teacher.id).update({ ExactLicences: teacher.Licences});
      this.ListTeachersSchool(this.SchoolSelected);
      this.dialogLoad= false;
    },
    async OnOffALLMultiplayer(){
      // console.log(this.switchMultiplayerAll);
      this.dialogLoad = true;
      for (const teacher of this.ListTeachers) {
        console.log(teacher);
        await db.collection('SchoolTeacher').doc(teacher.id).update({ Multiplayer: this.switchMultiplayerAll});
        if (this.switchMultiplayerAll) {
          let idClassroom = Object.keys(teacher.Classroom);
          for (const idClass of idClassroom) {
            let NStudents =teacher.Classroom[idClass].NumberStudents;
            let NGroup =teacher.Classroom[idClass].NumberGroup;
            let NgruposIdeales = Math.floor(NStudents/5)+1;
            if (NgruposIdeales> NGroup) {
              for (let index = NGroup; index < NgruposIdeales; index++) {
                
                let slug = this.NamesGroups[index].replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ').toLowerCase();
                slug = slug.replace(/^\s+|\s+$/gm, '');
                slug = slug.replace(/\s+/g, '-');

                await db.collection('SchoolTeacher').doc(teacher.id).collection('Classrooms').doc(idClass).update({ [`Groups.${[slug]}`]: { Name: this.NamesGroups[index], StudentsList: [] } });
                await db.collection("SchoolTeacher").doc(teacher.id).update({ [`Classrooms.${idClass}.NumberGroup`]: firebase.firestore.FieldValue.increment(1) })
              }  
            }
          } 
        }
      }
      this.ListTeachersSchool(this.SchoolSelected)
      this.dialogLoad= false;
    },
    allCheckBoxMultiplayer(){
      var checkboxes = document.querySelectorAll('#checkboxM');
      if (this.allCheckM){              
        for (var i = 0; i < checkboxes.length; i++) {
            var checkbox = checkboxes[i];
            checkbox.checked = true;
            checkbox.dispatchEvent(new Event('change'));      
        }
      }else{
        for (var i = 0; i < checkboxes.length; i++) {
            var checkbox = checkboxes[i];
            checkbox.checked = false;
            checkbox.dispatchEvent(new Event('change'));       
        }
      }
    },
    allCheckBoxClassrooms(){
      //alert("Seleccionamos todos...");
      var checkboxes = document.querySelectorAll('#checkbox');
      if (this.allCheck){              
        for (var i = 0; i < checkboxes.length; i++) {
            var checkbox = checkboxes[i];
            checkbox.checked = true;
            checkbox.dispatchEvent(new Event('change'));      
        }
      }else{
        for (var i = 0; i < checkboxes.length; i++) {
            var checkbox = checkboxes[i];
            checkbox.checked = false;
            checkbox.dispatchEvent(new Event('change'));       
        }
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedUID === -1 ? "New School" : "Edit School";
    },
    labelStudent() {
      return this.editedUID === -1
        ? "Number of Student Licenses"
        : "Student Licence to ADD";
    },
    labelTeacher() {
      return this.editedUID === -1
        ? "Number of Teacher Licenses"
        : "Teacher Licence to ADD";
    },
  },
  watch: {
    dialogSchool(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogListTeachers( val ) {
      val || this.closeList();
    },
    dialogListTeachersClassrooms( val ) {
      val || this.closeList();
    },
    dialogListTeachersDownloads( val ) {
      val || this.closeList();
    },
    dialogLoad(val) {
      console.log(val);
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  },
};
</script>
<style scoped>
.my-header-style {
  background: #FFF1e4;
  color: #000 !important;
  font-size: 18px !important;
  border: 1px solid #E2FAFD;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

/*.tblDownloads .text-start{
  background: #000 !important;
  border: 1px solid #E2FAFD !important;
}*/

.downloadContent{  
  border: 1px solid #E2FAFD !important;
  white-space: nowrap;
}
</style>
